// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-christmas-lyrics-emotions-demo-tsx": () => import("./../../../src/pages/christmas-lyrics-emotions-demo.tsx" /* webpackChunkName: "component---src-pages-christmas-lyrics-emotions-demo-tsx" */),
  "component---src-pages-nlp-timeline-demo-tsx": () => import("./../../../src/pages/nlp-timeline-demo.tsx" /* webpackChunkName: "component---src-pages-nlp-timeline-demo-tsx" */),
  "component---src-pages-periodic-table-demo-tsx": () => import("./../../../src/pages/periodic-table-demo.tsx" /* webpackChunkName: "component---src-pages-periodic-table-demo-tsx" */),
  "component---src-pages-scattertext-demo-tsx": () => import("./../../../src/pages/scattertext-demo.tsx" /* webpackChunkName: "component---src-pages-scattertext-demo-tsx" */),
  "component---src-templates-author-tsx": () => import("./../../../src/templates/author.tsx" /* webpackChunkName: "component---src-templates-author-tsx" */),
  "component---src-templates-index-tsx": () => import("./../../../src/templates/index.tsx" /* webpackChunkName: "component---src-templates-index-tsx" */),
  "component---src-templates-post-tsx": () => import("./../../../src/templates/post.tsx" /* webpackChunkName: "component---src-templates-post-tsx" */),
  "component---src-templates-tags-tsx": () => import("./../../../src/templates/tags.tsx" /* webpackChunkName: "component---src-templates-tags-tsx" */)
}

